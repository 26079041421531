export default class Policy {
  id!: number
  member_number!: number
  client_identification!: string
  policy_number!: number
  module!: string
  coin_type!: string
  premium_amount!: number
  policy_type!: string
  policy_status!: string
  date!: Date
  next_payment!: number
  periodicity!: Date
  representative_data!: string
  observation!: string
}
